import "./DashboardOwnersList.less";
import { AtTabCard } from "@atman/design-system";
import {
    ClientStore,
    GlobalStores,
    IAppContext,
    IOwnersUsageModel,
    PartnerStore,
    ScopedOrganizationStore,
} from "@atman/business";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inject, observer } from "mobx-react";
import React from "react";
// @ts-ignore
import { BaseColumn } from "../../../components/BaseItemList";
import { ISortElement } from "../../../components/BaseItemList";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { OwnerNameColumn, ProductUsageColumn, TotalUsageColumn } from "./TableColumns";
import { toJS } from "mobx";
import { withAppContext } from "../../../contexts";
import BootstrapTable from "react-bootstrap-table-next";

export interface IDashboardOwnersListProps extends IAppContext {
    data?: Dictionary<string, IOwnersUsageModel>;
    filterDaysCount: number;
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
    scopedOrganizationStore?: ScopedOrganizationStore;
}

@inject(GlobalStores.clientStore, GlobalStores.partnerStore, GlobalStores.scopedOrganizationStore)
@observer
class DashboardOwnersListComp extends React.Component<IDashboardOwnersListProps, {}> {
    render() {
        const { scope, data, clientStore, partnerStore, scopedOrganizationStore } = this.props;

        if (!data) {
            return (
                <AtTabCard id={"ProductUsageDashboard"} cardTitle={"global.overall".localize()}>
                    <FontAwesomeIcon icon={["far", "spinner"] as IconProp} spin />
                </AtTabCard>
            );
        }

        const mappedData = Object.keys(data).map(
            (x) =>
                ({
                    ownerId: x,
                    total: data[x].total,
                    ...data[x].productsUsage,
                } as OwnersListDataEntry),
        );

        const defaultSorted: ISortElement[] = [
            {
                dataField: "total",
                order: "desc",
            },
        ];

        const tableColumns: BaseColumn[] = [
            new OwnerNameColumn(scope, clientStore!, partnerStore!),
            new TotalUsageColumn(),
            ...(scopedOrganizationStore!.scopedOrganization.configurableProducts?.map(
                (x) => new ProductUsageColumn(x),
            ) ?? []),
        ];

        return (
            <div id="DashboardOwnersList">
                <BootstrapTable
                    keyField={"id"}
                    columns={tableColumns}
                    data={toJS(mappedData)}
                    defaultSorted={defaultSorted}
                    bordered={false}
                    bootstrap4
                />
            </div>
        );
    }
}

export type OwnersListDataEntry = IOwnersUsageModel & { ownerId: string };

const DashboardOwnersList = withAppContext(DashboardOwnersListComp);

export { DashboardOwnersList };
