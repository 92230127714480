import "../index.less";
import "./index.less";
import * as React from "react";
import { AssessmentStructure, CompDevCompetencyResult, CompetencyDevelopmentProcessResultsData } from "@atman/business";
import { CompetencyDevelopmentColumnType, IPersonInfo, TableHeaderRow, TableRow } from "../../..";
import { DetailedSubCompetencySubRow } from "../components/TableSubRow/DetailedSubCompetencySubRow";
import { SortDirection } from "../../../../..";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";

export interface IDetailedCompetencyResultTableProps {
    assessmentStructure?: AssessmentStructure;
    processResultsData: CompetencyDevelopmentProcessResultsData;
    competencies: CompDevCompetencyResult[];
    columns: CompetencyDevelopmentColumnType[];
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    isReadOnly?: boolean;
    showRank?: boolean;
    sortElement: CompetencyDevelopmentColumnType;
    sortDirection: SortDirection;
    hiddenColumns?: CompetencyDevelopmentColumnType[];
    onSortChange: (element: CompetencyDevelopmentColumnType) => void;
    saveAssessmentFunction?: (
        competencyId: string,
        observableBehaviorScores: Map<string, number | undefined>,
        observableBehaviorNotes: Map<string, string | undefined>,
    ) => Promise<void>;
    withEvaluator?: boolean;
}

export const DetailedCompetencyResultTable: React.FC<IDetailedCompetencyResultTableProps> = observer((props) => {
    const {
        assessmentStructure,
        processResultsData,
        competencies,
        columns,
        managerInfo,
        employeeInfo,
        sortElement,
        sortDirection,
        hiddenColumns,
        isReadOnly = false,
        showRank = true,
        onSortChange,
        saveAssessmentFunction,
        withEvaluator,
    } = props;

    const { isMobile } = useDetectDevice();

    return (
        <div className="ResultTable DetailedCompetencyResultTable">
            {!isMobile && (
                <TableHeaderRow
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                    managerInfo={managerInfo}
                    employeeInfo={employeeInfo}
                    columns={columns}
                    hiddenColumns={hiddenColumns}
                />
            )}
            {competencies.map((x) => (
                <TableRow
                    dataRow={x}
                    columns={columns}
                    key={x.id}
                    scoresColumnSet={"reviewScores"}
                    managerInfo={managerInfo}
                    employeeInfo={employeeInfo}
                    showRank={showRank}
                    hiddenColumns={hiddenColumns}
                    collapseContentOffset={true}
                    averageScoreDisplayMode={"solid"}
                >
                    {x.subCompetencies!.map((group) => (
                        <DetailedSubCompetencySubRow
                            subCompetency={group}
                            key={group.id}
                            parentCompetencyId={x.id}
                            assessmentStructure={assessmentStructure}
                            processResultsData={processResultsData}
                            managerInfo={managerInfo}
                            employeeInfo={employeeInfo}
                            isReadOnly={isReadOnly}
                            saveAssessmentFunction={saveAssessmentFunction}
                            showRank={showRank}
                            columns={columns}
                            hiddenColumns={hiddenColumns}
                            withEvaluator={withEvaluator}
                        />
                    ))}
                </TableRow>
            ))}
        </div>
    );
});
