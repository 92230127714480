import "./DashboardGraph.less";
import {
    DateGraphFrequency,
    GlobalStores,
    IDateGraph,
    ProductCodeProEnum,
    ScopedOrganizationStore,
} from "@atman/business";
import { inject, observer } from "mobx-react";
import React from "react";
// @ts-ignore
import * as moment from "moment";
import { AtButton, AtTabCard } from "@atman/design-system";
import { AtVictoryTheme } from "../../../styles/components/AtVictoryTheme";
import { ButtonGroup } from "reactstrap";
import { Colors } from "../../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    VictoryAxis,
    VictoryChart,
    VictoryClipContainer,
    VictoryLabel,
    VictoryLine,
    VictoryScatter,
    VictoryTooltip,
    VictoryVoronoiContainer,
} from "victory";
import { action, computed, observable } from "mobx";
import { t } from "@lingui/macro";
import autobind from "autobind-decorator";

export interface IDashboardGraphProps {
    data?: Dictionary<DateGraphFrequency, IDateGraph[]>;
    filterDaysCount: number;
    scopedOrganizationStore?: ScopedOrganizationStore;
}

@inject(GlobalStores.scopedOrganizationStore)
@observer
export class DashboardGraph extends React.Component<IDashboardGraphProps, {}> {
    @observable public frequency: DateGraphFrequency = DateGraphFrequency.Weekly;
    @observable public displayAllProductsData: boolean = true;

    componentDidUpdate(prevProps: Readonly<IDashboardGraphProps>, prevState: Readonly<{}>, snapshot?: any): void {
        this.updateFrequencyForPeriod();
    }

    private updateFrequencyForPeriod = () => {
        const { filterDaysCount } = this.props;

        switch (this.frequency) {
            case DateGraphFrequency.Daily:
                if (filterDaysCount > 92) {
                    this.frequency = DateGraphFrequency.Weekly;
                } else if (filterDaysCount > 365) {
                    this.frequency = DateGraphFrequency.Monthly;
                }
                break;
            case DateGraphFrequency.Weekly:
                if (filterDaysCount < 14) {
                    this.frequency = DateGraphFrequency.Daily;
                } else if (filterDaysCount > 365) {
                    this.frequency = DateGraphFrequency.Monthly;
                }
                break;
            case DateGraphFrequency.Monthly:
                if (filterDaysCount < 14) {
                    this.frequency = DateGraphFrequency.Daily;
                } else if (filterDaysCount < 60) {
                    this.frequency = DateGraphFrequency.Weekly;
                }
                break;
        }
    };

    @computed
    get mappedData() {
        const { data } = this.props;

        const dataForFrequency = data![this.frequency] as IDateGraph[];

        switch (this.frequency) {
            case DateGraphFrequency.Daily:
                return dataForFrequency.map(({ startDate, endDate, count, total, productsUsage }) => ({
                    tooltipLabel: moment.parseZone(startDate).startOf("day").format("MMM DD"),
                    x: moment.parseZone(startDate).startOf("day").toDate(),
                    count,
                    total,
                    ...productsUsage,
                }));
            case DateGraphFrequency.Weekly:
                return dataForFrequency.map(({ startDate, endDate, count, total, productsUsage }) => ({
                    tooltipLabel: `${moment.parseZone(startDate).format("MMM DD")} - ${moment
                        .parseZone(endDate)
                        .format("MMM DD")}`,
                    x: moment.parseZone(startDate).toDate(),
                    count,
                    total,
                    ...productsUsage,
                }));
            case DateGraphFrequency.Monthly:
                return dataForFrequency.map(({ startDate, count, total, productsUsage }) => ({
                    tooltipLabel: moment.parseZone(startDate).format("MMMM YYYY"),
                    x: moment.parseZone(startDate).toDate(),
                    count,
                    total,
                    ...productsUsage,
                }));
        }
    }

    @autobind
    private getDataSetForProduct(productCode: ProductCodeProEnum | "total", labels: boolean = true) {
        return this.mappedData.map((x) => ({
            ...x,
            l: labels,
        }));
    }

    @action.bound
    updateFrequency = (event: React.FormEvent<HTMLButtonElement>) => {
        this.frequency = Number((event.target as any).value);
    };

    @action.bound
    updateDisplayAllProductsData = (event: React.FormEvent<HTMLButtonElement>) => {
        this.displayAllProductsData = (event.target as any).value === "true";
    };

    @autobind
    renderCustomAtTabCardContent() {
        const { filterDaysCount } = this.props;

        return (
            <div className={"filters-section"}>
                <ButtonGroup className={"all-products-filter"}>
                    <AtButton
                        size={"md"}
                        color={this.displayAllProductsData ? "primary" : "secondary"}
                        onClick={this.updateDisplayAllProductsData}
                        value={true.toString()}
                        className={this.displayAllProductsData ? "selected" : ""}
                    >
                        {"global.all".localize()}
                    </AtButton>
                    <AtButton
                        size={"md"}
                        color={!this.displayAllProductsData ? "primary" : "secondary"}
                        onClick={this.updateDisplayAllProductsData}
                        value={false.toString()}
                        className={!this.displayAllProductsData ? "selected" : ""}
                    >
                        {"global.total".localize()}
                    </AtButton>
                </ButtonGroup>
                <ButtonGroup className={"frequency-filter"}>
                    <AtButton
                        size={"md"}
                        color={this.frequency === DateGraphFrequency.Daily ? "primary" : "secondary"}
                        onClick={this.updateFrequency}
                        value={DateGraphFrequency.Daily}
                        disabled={filterDaysCount > 92}
                        className={this.frequency === DateGraphFrequency.Daily ? "selected" : ""}
                    >
                        {`global.frequencies.${DateGraphFrequency[DateGraphFrequency.Daily].toCamel()}`.localize()}
                    </AtButton>
                    <AtButton
                        size={"md"}
                        color={this.frequency === DateGraphFrequency.Weekly ? "primary" : "secondary"}
                        onClick={this.updateFrequency}
                        value={DateGraphFrequency.Weekly}
                        disabled={filterDaysCount < 14 || filterDaysCount > 365}
                        className={this.frequency === DateGraphFrequency.Weekly ? "selected" : ""}
                    >
                        {`global.frequencies.${DateGraphFrequency[DateGraphFrequency.Weekly].toCamel()}`.localize()}
                    </AtButton>
                    <AtButton
                        size={"md"}
                        color={this.frequency === DateGraphFrequency.Monthly ? "primary" : "secondary"}
                        onClick={this.updateFrequency}
                        value={DateGraphFrequency.Monthly}
                        disabled={filterDaysCount < 60}
                        className={this.frequency === DateGraphFrequency.Monthly ? "selected" : ""}
                    >
                        {`global.frequencies.${DateGraphFrequency[DateGraphFrequency.Monthly].toCamel()}`.localize()}
                    </AtButton>
                </ButtonGroup>
            </div>
        );
    }

    getStrokeColor = (productCode: ProductCodeProEnum) => {
        switch (productCode) {
            case ProductCodeProEnum.CognitiveTest:
                return Colors.cognitiveAssessmentColor;
            case ProductCodeProEnum.PersonalityTest:
            case ProductCodeProEnum.PremiumPersonalityTest:
                return Colors.personalityAssessmentColor;
            case ProductCodeProEnum.PreferencesTest:
                return Colors.preferenceAssessmentColor;
            case ProductCodeProEnum.LearningModeTest:
                return Colors.learningAssessmentColor;
            case ProductCodeProEnum.TripleBottomLineTest:
                return Colors.tripleBottomLineAssessmentColor;
            case ProductCodeProEnum.PersonalityStyles:
                return Colors.greyShades.shade6;
            case ProductCodeProEnum.Integral:
            case ProductCodeProEnum.PremiumIntegral:
            case ProductCodeProEnum.PremiumIntegralWithoutCognitive:
            default:
                return Colors.atmanOrange;
        }
    };

    render() {
        const { data, scopedOrganizationStore } = this.props;

        if (!data) {
            return (
                <AtTabCard id={"DashboardGraph"} cardTitle={"global.graph".localize()} className={"loading"}>
                    <FontAwesomeIcon icon={["far", "spinner"] as IconProp} spin />
                </AtTabCard>
            );
        }

        const dataSetIsEmpty = !this.mappedData.some((x) => x.count > 0);
        const tickValuesForEmptyDataSet = dataSetIsEmpty ? { tickValues: [0, 1], maxDomain: { y: 1 } } : {};

        const victoryTooltip = (
            <VictoryTooltip
                flyoutStyle={AtVictoryTheme.tooltip.flyout}
                // @ts-ignore
                style={AtVictoryTheme.tooltip.style}
                constrainToVisibleArea
                labelComponent={<VictoryLabel className={"tooltip-label"} />}
            />
        );

        const availableProducts = scopedOrganizationStore!.scopedOrganization.configurableProducts ?? [];

        const labelFormatMethod = ({ datum: { tooltipLabel, l, total, ...otherDatum } }) => {
            if (!l) {
                return null;
            }

            let label = `${tooltipLabel}
            ${"global.total".localize()}: ${total}`;

            for (const product of availableProducts) {
                const productName = t({
                    id: `global.productCodes.${ProductCodeProEnum[product].toCamel()}`,
                });

                label += `
                ${productName}: ${otherDatum[product]}`;
            }

            return label;
        };

        const victoryVoronoiContainer = (
            <VictoryVoronoiContainer
                voronoiDimension={"x"}
                height={300}
                width={900}
                voronoiPadding={1}
                // @ts-ignore
                labels={labelFormatMethod}
                labelComponent={victoryTooltip}
                voronoiBlacklist={["scatter_total", ...availableProducts.map((x) => `scatter_${x}`)]}
            />
        );
        const victoryClipContainer = <VictoryClipContainer clipHeight={305} clipPadding={{ top: 2 }} />;

        const victoryScatterTotal = (
            <VictoryScatter
                y={(x) => x.total}
                name={`scatter_total`}
                key={"total"}
                data={this.getDataSetForProduct("total", false)}
            />
        );

        const individualVictoryScatters = availableProducts.map((productCode) => {
            return (
                <VictoryScatter
                    y={(x) => x[productCode]}
                    name={`scatter_${productCode}`}
                    key={productCode}
                    data={this.getDataSetForProduct(productCode, false)}
                    style={{ data: { stroke: this.getStrokeColor(productCode) } }}
                />
            );
        });

        const chart = (
            <VictoryChart
                height={300}
                width={900}
                scale={{ x: "time", y: "linear" }}
                containerComponent={victoryVoronoiContainer}
                // @ts-ignore
                theme={AtVictoryTheme}
            >
                <VictoryAxis dependentAxis {...tickValuesForEmptyDataSet} />
                <VictoryAxis
                    style={{
                        grid: {
                            strokeWidth: 0,
                        },
                        axis: {
                            strokeWidth: 1,
                        },
                    }}
                />
                <VictoryLine
                    y={(x) => x.total}
                    interpolation={"monotoneX"}
                    data={this.getDataSetForProduct("total")}
                    groupComponent={victoryClipContainer}
                />
                {this.displayAllProductsData
                    ? availableProducts.map((productCode) => {
                          return (
                              <VictoryLine
                                  y={(x) => x[productCode]}
                                  key={productCode}
                                  interpolation={"monotoneX"}
                                  data={this.getDataSetForProduct(productCode, false)}
                                  groupComponent={victoryClipContainer}
                                  style={{ data: { stroke: this.getStrokeColor(productCode) } }}
                              />
                          );
                      })
                    : null}
                {victoryScatterTotal}
                {this.displayAllProductsData ? individualVictoryScatters : null}
            </VictoryChart>
        );

        return (
            <AtTabCard
                id="DashboardGraph"
                cardTitle={"global.graph".localize()}
                rightHeaderContent={this.renderCustomAtTabCardContent()}
            >
                {chart}
            </AtTabCard>
        );
    }
}
