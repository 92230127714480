import "./index.less";
import * as React from "react";
import {
    AppScope,
    GlobalStores,
    ModalStore,
    ModalTypes,
    ProductCodeProEnum,
    UrlFormatter,
    UsageModel,
} from "@atman/business";
import { AtButton, AtLink, AtTitle } from "@atman/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ProductBalanceElement } from "../ProductBalanceElement";
import { UnlimitedBalanceMarker } from "../AtHeader/components/UnlimitedBalanceMarker";
import { inject, observer } from "mobx-react";
import { useAppContext } from "../../contexts";
import { useHistory } from "react-router";

export interface IProductBalanceViewProps {
    ownerId: string;
    configurableProducts: ProductCodeProEnum[];
    usageModel: UsageModel;
    scopeOverride?: AppScope;
    modalStore?: ModalStore;
}

export const ProductBalanceView: React.FC<IProductBalanceViewProps> = inject(GlobalStores.modalStore)(
    observer((props) => {
        const { configurableProducts, usageModel, scopeOverride, ownerId, modalStore } = props;

        const history = useHistory();
        const { scope } = useAppContext();

        const openAttributeCredits = () => {
            modalStore!.openModal(ModalTypes.AttributeCredits, { recipientId: ownerId });
        };

        const goToBalanceHistory = () => {
            history.push(UrlFormatter.formatReactPath("/Transactions"), {
                ownerId: ownerId,
            });
        };

        let headerTitle: string = "";

        const scopeForComponent = scopeOverride !== undefined ? scopeOverride : scope;

        switch (scopeForComponent) {
            case AppScope.Client:
                headerTitle = "partnerApp.clientBalance".localize();
                break;
            case AppScope.Partner:
                headerTitle = "supplierApp.partnerBalance".localize();
                break;
            case AppScope.Supplier:
                headerTitle = "supplierApp.supplierBalance".localize();
                break;
        }

        const usageModelClass = new UsageModel(usageModel);

        return (
            <div id="ProductBalanceView">
                <div className="header">
                    <span className="icon-container fa-layers fa-fw">
                        <FontAwesomeIcon icon={["far", "wallet"] as IconProp} />
                    </span>
                    <AtTitle headingType={4} title={headerTitle} />
                </div>
                <div className="content">
                    {usageModelClass.isUnlimited ? (
                        <UnlimitedBalanceMarker />
                    ) : (
                        <>
                            <div className="products">
                                {configurableProducts.map((x, i) => {
                                    const balanceValue = usageModel.productBalance[x];
                                    const disabled =
                                        usageModel.productRestriction &&
                                        usageModel.productRestriction.length > 0 &&
                                        !usageModel.productRestriction.some((restriction) => restriction === Number(x));

                                    return (
                                        <ProductBalanceElement
                                            productCode={Number(x)}
                                            balanceValue={balanceValue}
                                            key={i}
                                            disabled={disabled}
                                        />
                                    );
                                })}
                            </div>
                            <AtButton color={"primary"} size={"lg"} onClick={openAttributeCredits}>
                                {"global.attributeProducts".localize()}
                            </AtButton>
                            <AtLink
                                color={"secondary"}
                                className="view-balance-history-link"
                                onClick={goToBalanceHistory}
                            >
                                {"global.viewBalanceHistory".localize()}
                            </AtLink>
                        </>
                    )}
                </div>
            </div>
        );
    }),
);
