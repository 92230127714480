import "./index.less";
import * as React from "react";
import { AssessmentType, ProductCodeProEnum } from "@atman/business";
import { ProductIcon } from "../ProductIcon";

export interface IProductInfoProps {
    productCode?: ProductCodeProEnum;
    assessmentType?: AssessmentType;
    productCategory?: string;
}

export const ProductInfo: React.FC<IProductInfoProps> = (props: IProductInfoProps) => {
    const proAssessments: AssessmentType[] = [
        AssessmentType.ProCognitiveTest,
        AssessmentType.ProPersonalityTest,
        AssessmentType.ProPreferencesTest,
        AssessmentType.ProLearningTest,
        AssessmentType.ProTripleBottomLineTest,
    ];
    const proProducts: ProductCodeProEnum[] = [
        ProductCodeProEnum.Integral,
        ProductCodeProEnum.PersonalityStyles,
        ProductCodeProEnum.CognitiveTest,
        ProductCodeProEnum.PersonalityTest,
        ProductCodeProEnum.PreferencesTest,
        ProductCodeProEnum.LearningModeTest,
        ProductCodeProEnum.TripleBottomLineTest,
    ];

    const { productCode, assessmentType, productCategory } = props;

    let productName: string = "";
    let productIcon: React.ReactNode;
    let category = productCategory;

    if (category === undefined) {
        if (
            (assessmentType !== undefined && proAssessments.includes(assessmentType)) ||
            (productCode !== undefined && proProducts.includes(productCode))
        ) {
            category = "AtmanPro";
        } else {
            category = "Premium";
        }
    }

    if (productCode !== undefined) {
        productName = `global.productCodes.${ProductCodeProEnum[productCode].toCamel()}`.localize();
        productIcon = <ProductIcon productCode={productCode} />;
    } else if (assessmentType !== undefined) {
        productName = `psychometrics.assessments.${(AssessmentType[assessmentType] as string).toCamel()}`.localize();
        productIcon = <ProductIcon assessmentType={assessmentType} />;
    }

    return (
        <div className="ProductInfo">
            {productIcon}
            <div className="product-info">
                <div className="product-group">{category}</div>
                <div className="product-name">{productName}</div>
            </div>
        </div>
    );
};
