import "./index.less";
import * as React from "react";
import { ProductCodeProEnum } from "@atman/business";
import { ProductInfo } from "../ProductInfo";
import { observer } from "mobx-react";

export interface IProductBalanceElementProps {
    productCode: ProductCodeProEnum;
    balanceValue: number;
    disabled?: boolean;
}

export const ProductBalanceElement: React.FC<IProductBalanceElementProps> = observer((props) => {
    const { productCode, balanceValue, disabled } = props;

    return (
        <div className={`ProductBalanceElement ${disabled ? "disabled" : ""}`}>
            <ProductInfo productCode={productCode} />
            <div className="right-product-section">
                <div className="product-quantity">{balanceValue || 0}</div>
            </div>
        </div>
    );
});
