import "./index.less";
import * as React from "react";
import { AtScaleLegend, ReportPageHeader } from "../..";
import { AtSubtitle, AtText, AtTitle } from "@atman/design-system";
import { BaseAtScale } from "../BaseAtScale";
import { BaseAtScaleGroup } from "../BaseAtScaleGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    GenericAssessmentType,
    PrintModeEnum,
    ReportType,
    ScaleMaxScore,
    ScaleType,
    getGenericAssessmentType,
} from "@atman/business";
import { STANINE_SCALE_SEPARATORS } from "../../AtGenericScales";
import { UncontrolledTooltip } from "reactstrap";
import cn from "classnames";

export interface IAtBipolarScaleGroupProps {
    onViewCandidateDetailClick?: (candidateProId: string) => void;
    className?: string;
    subTitle?: string;
    highlightedColor?: string;
    bannerImageUrl?: string;
    reportType?: ReportType;
}

export class AtBipolarScaleGroup extends BaseAtScaleGroup<IAtBipolarScaleGroupProps> {
    render() {
        const {
            maxScore,
            assessmentType,
            dimensionGroup,
            subTitle,
            distributionData,
            distributionViewMode,
            showDistribution,
            distributionMembers,
            expandedDimension,
            onExpandedDimensionChange,
            showHeader = true,
            showDescription = true,
            showFitIndicators,
            interpretationDataEntries,
            printMode,
            onViewCandidateDetailClick,
            className,
            highlightedColor,
            bannerImageUrl,
            documentStructure,
            isInTeamView,
            reportType,
        } = this.props;

        const tooltipId = `${this.id}-tooltip`;

        const subtitleTitle = (
            <div className="avoidPageBreakCompatibilityFitReport">
                {subTitle && <AtSubtitle subtitle={subTitle} />}

                {printMode !== undefined ? (
                    <AtText weight="bold">{dimensionGroup.name}</AtText>
                ) : (
                    <AtTitle headingType={3} title={dimensionGroup.name} />
                )}
            </div>
        );

        const preferencesContentForPrint = (index, bannerImageUrl): JSX.Element | null => {
            if (printMode === PrintModeEnum.Detailed) {
                if (index % 3 === 0 || isInTeamView) {
                    return <ReportPageHeader size="sm" imageUrl={bannerImageUrl} />;
                }
                return null;
            }

            return null;
        };

        const genericAssessmentType = assessmentType ? getGenericAssessmentType(assessmentType) : undefined;

        const isPersonalityOrPreferencesReport =
            genericAssessmentType === GenericAssessmentType.PersonalityTest ||
            genericAssessmentType === GenericAssessmentType.PreferencesTest;

        return (
            <div
                className={cn("AtBipolarScaleGroup", className, this.baseClassName, {
                    detailed: printMode === PrintModeEnum.Detailed,
                })}
            >
                {showHeader && (
                    <div className="scale-group-header">
                        {genericAssessmentType !== GenericAssessmentType.PreferencesTest && subtitleTitle}

                        {showDescription && dimensionGroup.description && (
                            <>
                                <FontAwesomeIcon id={tooltipId} icon="question-circle" size="xs" />
                                <UncontrolledTooltip
                                    innerClassName="scale-group-bipolar-tooltip"
                                    placement="right"
                                    target={tooltipId}
                                >
                                    {dimensionGroup.description!.split(/\\n|<br\/><br\/>|<br>|<br\/>/).map((x) => (
                                        <React.Fragment key={x}>
                                            <span>{x}</span>
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </UncontrolledTooltip>
                            </>
                        )}
                    </div>
                )}
                <div className="results-container">
                    {dimensionGroup.dimensions.map((x, index) => {
                        return (
                            <div key={x.enumName} className="group-scale-container-and-banner">
                                {genericAssessmentType === GenericAssessmentType.PreferencesTest &&
                                    bannerImageUrl &&
                                    preferencesContentForPrint(index, bannerImageUrl)}
                                <div className="group-scale-container" key={x.enumName}>
                                    {bannerImageUrl &&
                                        (!assessmentType ||
                                            genericAssessmentType === GenericAssessmentType.PersonalityTest) && (
                                            <ReportPageHeader size="sm" imageUrl={bannerImageUrl ?? ""} />
                                        )}

                                    {printMode === PrintModeEnum.Detailed &&
                                        documentStructure &&
                                        (isPersonalityOrPreferencesReport || x.atCompatibilityFitData) && (
                                            <AtScaleLegend
                                                className="outterScale"
                                                separators={STANINE_SCALE_SEPARATORS}
                                                sectionLabels={documentStructure.scaleLabels}
                                                maxScore={ScaleMaxScore.Stanine}
                                                scaleType={documentStructure?.scaleType}
                                                showScaleValues={documentStructure.scaleType === ScaleType.Bipolar}
                                                showScoreLabels={documentStructure.scaleType !== ScaleType.Bipolar}
                                            />
                                        )}

                                    <BaseAtScale
                                        scaleType={ScaleType.Bipolar}
                                        dimension={x}
                                        maxScore={maxScore}
                                        assessmentType={assessmentType}
                                        scores={this.getScoresForDimension(x.enumName)}
                                        distributionMembers={distributionMembers}
                                        distributionEntries={distributionData?.getEntriesForDimension(x.enumName)}
                                        distributionAverage={distributionData?.getAverageForDimension(x.enumName)}
                                        interpretationData={interpretationDataEntries?.get(x.enumName)}
                                        distributionViewMode={distributionViewMode}
                                        showDistribution={showDistribution}
                                        expandedDimension={expandedDimension}
                                        onExpandedDimensionChange={onExpandedDimensionChange}
                                        showFitIndicators={showFitIndicators}
                                        key={x.enumName}
                                        printMode={printMode}
                                        showDescription={
                                            printMode === undefined || printMode === PrintModeEnum.Detailed
                                        }
                                        onViewCandidateDetailClick={onViewCandidateDetailClick}
                                        separators={STANINE_SCALE_SEPARATORS}
                                        highlightedColor={highlightedColor}
                                        bannerImageUrl={bannerImageUrl}
                                        reportType={reportType}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
