import { BaseModel } from "../base/BaseModel";
import {
    IProductConsumptionModeScheduleEntryModel,
    ProRestrictionType,
    ProductCodeProEnum,
    ProductConsumptionMode,
} from "../types";
import { observable } from "mobx";
import moment from "moment";

export type CustomDate = {
    stringValue: string;
    dateValue: Date;
};

export class UsageModel extends BaseModel {
    public ownerId: string;
    @observable public productBalance: Dictionary<ProductCodeProEnum, number>;
    public productConsumptionModeSchedule: IProductConsumptionModeScheduleEntryModel[];
    public productRestriction: ProductCodeProEnum[];
    public negativeBalanceThreshold: Dictionary<ProductCodeProEnum, number>;
    public restrictionType: ProRestrictionType;
    public discountRate: number;

    constructor(usageModel: UsageModel) {
        super(usageModel);

        this.ownerId = usageModel.ownerId;
        this.productBalance = usageModel.productBalance;
        this.productConsumptionModeSchedule = usageModel.productConsumptionModeSchedule;
        this.productRestriction = usageModel.productRestriction;
        this.negativeBalanceThreshold = usageModel.negativeBalanceThreshold;
        this.restrictionType = usageModel.restrictionType;
        this.discountRate = usageModel.discountRate;
    }

    get currentScheduleEntry(): IProductConsumptionModeScheduleEntryModel | undefined {
        const now = moment();
        const activePeriod = this.productConsumptionModeSchedule.filter((x) => {
            return (
                now.isAfter(moment.parseZone(x.effectiveDate).startOf("D")) &&
                (!x.endDate || now.isBefore(moment.parseZone(x.endDate)))
            );
        });

        if (activePeriod.length === 0) {
            return undefined;
        }

        return activePeriod[0];
    }

    get isUnlimited(): boolean {
        const currentEntry = this.currentScheduleEntry;

        if (!currentEntry) {
            return false;
        }

        return currentEntry.consumptionMode === ProductConsumptionMode.Unlimited;
    }

    get creationDate(): CustomDate | undefined {
        const creationDate = this.currentScheduleEntry?.effectiveDate;

        if (creationDate) {
            const date = new Date(creationDate);
            return { stringValue: date.toLocaleDateString(), dateValue: date };
        }

        return undefined;
    }

    get endDate(): string | undefined {
        const endDate = this.currentScheduleEntry?.endDate;

        if (endDate) {
            return new Date(endDate).toLocaleDateString();
        }

        return undefined;
    }
}
