import { AssessmentType } from "../../types";
import { BaseFit, IBaseFit } from "./BaseFit";
import { GenericFitContent, IGenericFitContent } from "./GenericFitContent";
import { observable } from "mobx";

export interface IGenericFit extends IBaseFit<IGenericFitContent> {
    readonly genericFitType: GenericFitType;
    readonly targetCanvas: GenericFitTargetCanvas;
    readonly requiredAssessmentTypes: AssessmentType[];
}

export class GenericFit extends BaseFit<IGenericFitContent> implements IGenericFit {
    @observable public genericFitType: GenericFitType;
    public readonly targetCanvas: GenericFitTargetCanvas;
    public readonly requiredAssessmentTypes: AssessmentType[];
    public content: GenericFitContent;

    constructor(json: IGenericFit) {
        super(json);

        this.content = new GenericFitContent(json.content);
        this.genericFitType = json.genericFitType;
        this.targetCanvas = json.targetCanvas;
        this.requiredAssessmentTypes = json.requiredAssessmentTypes;
    }
}

export enum GenericFitType {
    Culture,
    Potential,
}

export type GenericFitTargetCanvas = "Default" | "Premium";
