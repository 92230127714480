import "./index.less";
import { AssessmentWithFooterContainer } from "../components/AssessmentWithFooterContainer";
import { AtContainer, Colors, IconTitleSubtitle } from "@atman/design-system";
import { AtWizardStepHeader } from "../../AtWizard";
import { BaseForm } from "../..";
import { CompetencyDevelopmentColumnType, ResultTableContainer } from "../../AtCompetencyDevelopmentComponents";
import { CompetencyDevelopmentFlowStyle, ISelectedObjectiveData, ToastProvider } from "@atman/business";
import { CustomAlert } from "../../CustomAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICompDevEvaluationContext, withCompDevEvaluationContext } from "../../../contexts";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import React from "react";

export interface ILeverageAssessmentContentProps extends ICompDevEvaluationContext {
    columnsOverride?: CompetencyDevelopmentColumnType[];
    fromExternalApp?: boolean;
}

@observer
export class LeverageAssessmentContentImpl extends BaseForm<ILeverageAssessmentContentProps> {
    @observable protected selectedElements: Map<string, Map<string, ISelectedObjectiveData>> = new Map<
        string,
        Map<string, ISelectedObjectiveData>
    >();

    @observable protected erroredElements: Map<string, string[]> = new Map<string, string[]>();

    @action setSelectedElements = (selectedElements: Map<string, Map<string, ISelectedObjectiveData>>): void => {
        this.selectedElements = selectedElements;
    };

    saveAssessment = async (): Promise<void> => {
        const { selectToLeverageObjectives, fromExternalApp = true } = this.props;

        this.erroredElements.clear();

        if (this.selectedElements.size === 0) {
            // No Competency Selected
            ToastProvider.error(
                t({
                    id: "competencyDevelopment.assessments.global.errors.noSelectedObjective",
                    message: "You need to select at least 1 objective",
                }),
            );
            return;
        }

        for (const [competencyId, selectedObjectives] of [...this.selectedElements]) {
            if (selectedObjectives.size === 0) {
                this.erroredElements.set(competencyId, []);
                return;
            }

            for (const [objectiveId, selectedObjectiveData] of [...selectedObjectives]) {
                if (
                    selectedObjectiveData.selectedApplicationIds.length === 0 &&
                    selectedObjectiveData.selectedLearningResourceIds.length === 0 &&
                    (selectedObjectiveData.applicationNotes?.length ?? 0) === 0 &&
                    (selectedObjectiveData.learningResourceNotes?.length ?? 0) === 0
                ) {
                    this.erroredElements.set(competencyId, [
                        ...(this.erroredElements.get(competencyId) ?? []),
                        objectiveId,
                    ]);
                }
            }
        }

        if (this.erroredElements.size > 0) {
            ToastProvider.error(
                t({
                    id: "competencyDevelopment.assessments.global.errors.noSelectedPracticalApplication",
                    message: "You need to select at least 1 practical application for each objective",
                }),
            );
            return;
        }

        try {
            this.setIsLoading(true);

            await selectToLeverageObjectives(this.selectedElements);

            this.setIsLoading(false);

            if (fromExternalApp) {
                window.close();

                window.setTimeout(
                    () =>
                        ToastProvider.success(
                            t({
                                id: "competencyDevelopment.assessments.alerts.finalize.toastSuccessMessage",
                                message: "Your changes have been saved. You may now close this window.",
                            }),
                        ),
                    250,
                );
            }
        } catch (e) {
            this.setIsLoading(false);
        }
    };

    openFinalizeAssessmentConfirmationAlert = (): void => {
        const { fromExternalApp = true } = this.props;

        const finalizeAssessmentAlert = new CustomAlert(
            this.saveAssessment,
            t({
                id: "competencyDevelopment.assessments.leveragingPlan.alerts.finalize.title",
                message: "Finalize your leveraging plan",
            }),
            (
                <div className="compdev-confirmation-alert-content">
                    <span className="content-details">
                        {t({
                            id: "competencyDevelopment.assessments.alerts.finalize.content",
                            message:
                                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
                        })}
                    </span>
                    {fromExternalApp && (
                        <div className="sync-warning">
                            <FontAwesomeIcon icon={["far", "sync"]} color={Colors.greyBase} />
                            <span>
                                {t({
                                    id: "competencyDevelopment.assessments.alerts.finalize.syncWarning",
                                    message: "Don't forget to sync the report to get up-to-date data.",
                                })}
                            </span>
                        </div>
                    )}
                </div>
            ),
            {},
            t({ id: "global.buttons.labels.saveAndClose", message: "Save and close" }),
            t({
                id: "competencyDevelopment.assessments.developmentPlans.alerts.finalize.cancelButton",
                message: "Continue the plan",
            }),
        );

        finalizeAssessmentAlert.fire(undefined, this.isMobile);
    };

    render(): JSX.Element {
        const { processResultsData, employeeDetails, managerDetails, columnsOverride, competencyDevelopmentFlowStyle } =
            this.props;

        const withEvaluator = competencyDevelopmentFlowStyle !== CompetencyDevelopmentFlowStyle.WithoutEvaluator;

        const compDevColumnsWithAndWithoutEvaluator: CompetencyDevelopmentColumnType[] = withEvaluator
            ? ["competencyName", "averageScore", "potential", "employeeScore", "managerScore", "spread"]
            : ["competencyName", "employeeScore"];
        const footerContent: JSX.Element = (
            <IconTitleSubtitle
                icon={<FontAwesomeIcon icon={["fal", "fist-raised"]} size="2x" />}
                title={t({
                    id: "competencyDevelopment.assessments.leveragingPlan.footerContent.title",
                    message: `${this.selectedElements.size} selected competencies`,
                    values: { 0: this.selectedElements.size },
                })}
                subtitle={t({
                    id: "competencyDevelopment.assessments.leveragingPlan.footerContent.subtitle",
                    message: `${[...this.selectedElements].sum(([_, cv]) =>
                        [...cv].sum(([_, sv]) => sv.selectedApplicationIds.length),
                    )} selected leveraging strategies`,
                    values: {
                        0: [...this.selectedElements].sum(([_, cv]) =>
                            [...cv].sum(([_, sv]) => sv.selectedApplicationIds.length),
                        ),
                    },
                })}
            />
        );

        return (
            <AssessmentWithFooterContainer
                id={"LeverageAssessmentContent"}
                className={"BaseDevelopmentPlanContent"}
                btnAction={this.openFinalizeAssessmentConfirmationAlert}
                btnLabel={t({ id: "global.buttons.labels.save", message: "Save" })}
                isLoading={this.isLoading}
                footerContent={footerContent}
            >
                <AtWizardStepHeader
                    title={t({
                        id: "competencyDevelopment.assessments.leveragingPlan.title",
                        message: "Competency Leveraging Plan",
                    })}
                    description={t({
                        id: "competencyDevelopment.assessments.leveragingPlan.description",
                        message:
                            "Select the competencies that would be ideal areas for the participant to seek fulfillment in their professional journey and have a positive impact on their work environment.",
                    })}
                />
                <AtContainer>
                    <IconTitleSubtitle
                        icon={<FontAwesomeIcon icon={["far", "hand-pointer"]} size="2x" />}
                        title={t({ id: "competencyDevelopment.assessments.leveragingPlan.header.title" })}
                        subtitle={t({
                            id: "competencyDevelopment.assessments.leveragingPlan.header.description",
                        })}
                        size="lg"
                    />
                    <ResultTableContainer
                        processResultsData={processResultsData!}
                        selectedElements={this.selectedElements}
                        dataType="competency"
                        displayType="developmentPlanLeverage"
                        columns={columnsOverride ?? compDevColumnsWithAndWithoutEvaluator}
                        employeeInfo={employeeDetails}
                        managerInfo={{
                            email: managerDetails.email ?? "",
                            firstName: managerDetails.firstName ?? "",
                            lastName: managerDetails.lastName ?? "",
                            displayName: managerDetails.displayName ?? "",
                            pictureUri: managerDetails.pictureUri,
                        }}
                        erroredElements={this.erroredElements}
                        onSelectedElementsChange={this.setSelectedElements}
                    />
                </AtContainer>
            </AssessmentWithFooterContainer>
        );
    }
}

export const LeverageAssessmentContent = withCompDevEvaluationContext(LeverageAssessmentContentImpl);
