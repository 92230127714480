import "./index.less";
import {
    AppScope,
    ClientStore,
    GlobalStores,
    IAppContext,
    PartnerStore,
    ProductBalanceStore,
    ProductCodeProEnum,
    UnhandledScopeError,
} from "@atman/business";
import { AtCheckbox, AtTabCard } from "@atman/design-system";
import { BaseForm } from "../BaseForm";
import { CardSaveButtons } from "../CardSaveButtons";
import { ProductInfo } from "../ProductInfo";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import React from "react";
import autobind from "autobind-decorator";

export interface IProductRestrictionFormProps extends IAppContext {
    ownerId: string;
    productRestriction: ProductCodeProEnum[];
    configurableProducts: ProductCodeProEnum[];
    productBalanceStore?: ProductBalanceStore;
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
}

@inject(GlobalStores.productBalanceStore, GlobalStores.clientStore, GlobalStores.partnerStore)
@observer
class ProductRestrictionFormComp extends BaseForm<IProductRestrictionFormProps, {}> {
    @observable public productRestriction: ProductCodeProEnum[] = [];

    componentDidMount(): void {
        this.resetProductRestriction();
    }

    @action.bound
    resetProductRestriction = () => {
        this.productRestriction = [...this.props.productRestriction];
    };

    @autobind
    async _onSave(): Promise<any> {
        this.setIsLoading(true);

        switch (this.props.scope) {
            case AppScope.Partner:
                await this.props.clientStore!.updateProductRestrictions(
                    this.props.ownerId,
                    this.productRestriction.slice(),
                );
                break;
            case AppScope.Supplier:
                await this.props.partnerStore!.updateProductRestrictions(
                    this.props.ownerId,
                    this.productRestriction.slice(),
                );
                break;
            default:
                this.setIsLoading(false);
                throw new UnhandledScopeError(this.props.scope);
        }

        this.setIsLoading(false);
    }

    validateForm(): boolean {
        return true;
    }

    @action.bound
    onProductRestrictionClick = (event: React.FormEvent<HTMLInputElement>) => {
        const productCode = Number((event.target as any).value);
        const index = this.productRestriction.indexOf(productCode);

        if (index >= 0) {
            this.productRestriction.splice(index, 1);
        } else {
            this.productRestriction.push(productCode);
        }
    };

    @autobind
    renderCheckboxes() {
        const { productBalanceStore, configurableProducts } = this.props;

        return configurableProducts.map((x, i) => (
            <AtCheckbox
                id={`${ProductCodeProEnum[Number(x)]}-restriction`}
                fieldName={"productRestriction"}
                key={i}
                onChange={this.onProductRestrictionClick}
                value={Number(x)}
                checked={this.productRestriction.some((productCode) => productCode === Number(x))}
                disabled={
                    productBalanceStore!.productRestriction &&
                    productBalanceStore!.productRestriction.length > 0 &&
                    !productBalanceStore!.productRestriction.some((productCode) => productCode === Number(x))
                }
            >
                <ProductInfo productCode={Number(x)} />
            </AtCheckbox>
        ));
    }

    render() {
        const {} = this.props;

        return (
            <AtTabCard
                cardTitle={"global.productRestriction".localize()}
                id="ProductRestrictionForm"
                className={"product-restriction-form"}
            >
                <div className="inputs">{this.renderCheckboxes()}</div>
                <CardSaveButtons
                    saveAction={this._onSave}
                    cancelAction={this.resetProductRestriction}
                    isLoading={this.isLoading}
                />
            </AtTabCard>
        );
    }
}

const ProductRestrictionForm = withAppContext(ProductRestrictionFormComp);

export { ProductRestrictionForm };
