import { AssessmentType } from "../types";

export enum GenericAssessmentType {
    CognitiveTest,
    PersonalityTest,
    PreferencesTest,
    TripleBottomLineTest,
    LearningTest,
}

const GENERIC_ASSESSMENT_TYPE_MAPPING = new Map<GenericAssessmentType, AssessmentType[]>([
    [GenericAssessmentType.CognitiveTest, [AssessmentType.ProCognitiveTest, AssessmentType.PremiumCognitiveTest]],
    [GenericAssessmentType.PersonalityTest, [AssessmentType.ProPersonalityTest, AssessmentType.PremiumPersonalityTest]],
    [GenericAssessmentType.PreferencesTest, [AssessmentType.ProPreferencesTest, AssessmentType.PremiumPreferencesTest]],
    [
        GenericAssessmentType.TripleBottomLineTest,
        [AssessmentType.ProTripleBottomLineTest, AssessmentType.PremiumTripleBottomLineTest],
    ],
    [GenericAssessmentType.LearningTest, [AssessmentType.ProLearningTest, AssessmentType.PremiumLearningTest]],
]);

export function getGenericAssessmentType(assessmentType: AssessmentType): GenericAssessmentType {
    for (const [genericType, specificTypes] of GENERIC_ASSESSMENT_TYPE_MAPPING.entries()) {
        if (specificTypes.includes(assessmentType)) {
            return genericType;
        }
    }

    throw new Error("Couldn't find a matching AssessmentType");
}

export function getSpecificAssessmentTypes(genericAssessmentType: GenericAssessmentType): AssessmentType[] {
    const specificAssessmentTypes = GENERIC_ASSESSMENT_TYPE_MAPPING.get(genericAssessmentType);

    if (!specificAssessmentTypes) {
        console.error("Couldn't find matching assessment types");
        return [];
    }

    return specificAssessmentTypes;
}
