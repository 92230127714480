import "./index.less";
import {
    AssessmentDocumentType,
    GenericAssessmentType,
    PrintModeEnum,
    ReportType,
    ScaleMaxScore,
    ScaleType,
    getGenericAssessmentType,
} from "@atman/business";
import { AtScaleLegend } from "..";
import { BaseAtScale } from "../BaseAtScale";
import { BaseAtScaleGroup } from "../BaseAtScaleGroup";
import { H3SectionLayout } from "../../AtDocumentLayout";
import { STANINE_SCALE_SEPARATORS } from "../../AtGenericScales";
import React from "react";
import cn from "classnames";

export interface IAtUnipolarScaleGroupProps {
    className?: string;
    scaleLabels?: string[];
    isCompetencyGroup?: boolean;
    showUnipolarScaleAsBipolar?: boolean;
    showDenominator?: boolean;
    printMode?: PrintModeEnum;
    onViewCandidateDetailClick?: (candidateProId: string) => void;
}

export class AtUnipolarScaleGroup extends BaseAtScaleGroup<IAtUnipolarScaleGroupProps> {
    render(): JSX.Element {
        const {
            className,
            dimensionGroup,
            maxScore,
            assessmentType,
            distributionData,
            distributionViewMode,
            showDistribution,
            distributionMembers,
            expandedDimension,
            onExpandedDimensionChange,
            showUnipolarScaleAsBipolar = false,
            showHeader = true,
            showDescription = true,
            showFitIndicators,
            interpretationDataEntries,
            printMode,
            showDenominator = false,
            displayCompact = false,
            onViewCandidateDetailClick,
            documentStructure,
            reportType,
            forceShowScoreCount,
            forceToHideHighLabel = false,
            scaleLabels,
        } = this.props;

        const genericAssessmentType = assessmentType ? getGenericAssessmentType(assessmentType) : undefined;

        return (
            <H3SectionLayout
                id={this.id}
                title={dimensionGroup.name}
                description={dimensionGroup.description}
                showDescription={showDescription}
                className={cn("AtUnipolarScaleGroup", className, {
                    detailed: printMode === PrintModeEnum.Detailed,
                    summary: printMode === PrintModeEnum.Summary,
                })}
                showHeader={showHeader}
            >
                {dimensionGroup.dimensions.map((x, index) => (
                    <div className="scale-legend-group-pair" key={`scale-legend-group-pair-${index}`}>
                        {printMode === PrintModeEnum.Detailed && (
                            <AtScaleLegend
                                separators={STANINE_SCALE_SEPARATORS}
                                maxScore={ScaleMaxScore.Stanine}
                                scaleType={ScaleType.Unipolar}
                                sectionLabels={scaleLabels}
                                showScaleValues
                                showScoreLabels
                            />
                        )}

                        <BaseAtScale
                            key={x.enumName}
                            scaleType={showUnipolarScaleAsBipolar ? ScaleType.Bipolar : ScaleType.Unipolar}
                            dimension={x}
                            maxScore={maxScore}
                            assessmentType={assessmentType}
                            scores={this.getScoresForDimension(x.enumName)}
                            distributionMembers={distributionMembers}
                            distributionEntries={distributionData?.getEntriesForDimension(x.enumName)}
                            distributionAverage={distributionData?.getAverageForDimension(x.enumName)}
                            interpretationData={interpretationDataEntries?.get(x.enumName)}
                            distributionViewMode={distributionViewMode}
                            showDistribution={showDistribution}
                            expandedDimension={expandedDimension}
                            onExpandedDimensionChange={onExpandedDimensionChange}
                            showFitIndicators={showFitIndicators}
                            printMode={printMode}
                            showDenominator={showDenominator}
                            displayCompact={displayCompact}
                            onViewCandidateDetailClick={onViewCandidateDetailClick}
                            separators={STANINE_SCALE_SEPARATORS}
                            useGreenBackgroundColor={
                                documentStructure?.assessmentDocumentType === AssessmentDocumentType.Competences ||
                                genericAssessmentType === GenericAssessmentType.TripleBottomLineTest ||
                                reportType === ReportType.Competencies
                            }
                            reportType={reportType}
                            forceShowScoreCount={forceShowScoreCount}
                            forceToHideHighLabel={forceToHideHighLabel}
                        />
                    </div>
                ))}
            </H3SectionLayout>
        );
    }
}
