import "./index.less";
import { AtBadge, AtBadgeStyle, DefaultComponentProps, DefaultSizes, XS } from "@atman/design-system";
import { CompetencyDevelopmentProcessStatus } from "@atman/business";
import {
    computeProcessStatusFormEmployee,
    getColorLabelForProcessStatus,
} from "@atman/client-app/src/app/libs/ComputeProcessStatus";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import React, { useMemo } from "react";
import cn from "classnames";
import moment from "moment";

export interface IProcessStatusProps extends DefaultComponentProps {
    status: CompetencyDevelopmentProcessStatus;
    dateStarted?: string;
    dateCompleted?: string;
    badgeSize?: XS | DefaultSizes;
    badgeStyle?: AtBadgeStyle;
    showDates?: boolean;
}

export const ProcessStatus: React.FC<IProcessStatusProps> = observer((props) => {
    const { status, dateStarted, dateCompleted, className, showDates = true } = props;

    const finalStatus = computeProcessStatusFormEmployee(status);
    const isStarted = finalStatus !== undefined;
    const labelColor = getColorLabelForProcessStatus(finalStatus);

    const date: string | undefined = useMemo(() => {
        switch (finalStatus) {
            case CompetencyDevelopmentProcessStatus.Completed:
                return `${t({ id: `global.compDevProcessStatus.completedOn` })} ${moment(dateCompleted).format(
                    "YYYY-MM-DD",
                )}`;
            case CompetencyDevelopmentProcessStatus.Started:
            case "InProgress":
                return `${t({ id: `global.compDevProcessStatus.startedOn` })} ${moment(dateStarted).format(
                    "YYYY-MM-DD",
                )}`;
            default:
                return undefined;
        }
    }, [dateCompleted, dateStarted, status]);
    return (
        <div className={cn("ProcessStatus", className)}>
            <div className="main">
                <AtBadge className="ProcessStatusColumn" color={labelColor.color} pill>
                    {labelColor.label}
                </AtBadge>
            </div>
            {showDates && isStarted && date && <div className="second">{date}</div>}
        </div>
    );
});
