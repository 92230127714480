import "./index.less";
import { DefaultComponentProps } from "../../../types";
import React from "react";

export interface IReportRangeLegendItemProps extends DefaultComponentProps {
    text: string;
    invertedRange?: boolean;
    iconClassName?: string;
}

export const ReportRangeLegendItem: React.FC<IReportRangeLegendItemProps> = ({
    text,
    invertedRange,
    iconClassName,
    ...otherProps
}: IReportRangeLegendItemProps) => {
    let classes: string = "legend-icon range-icon";

    if (iconClassName) {
        classes += ` ${iconClassName}`;
    }

    if (invertedRange) {
        classes += ` inverted`;
    }

    return (
        <div className="ReportLegendItem ReportRangeLegendItem" {...otherProps}>
            <div className={classes}></div>
            <span className="legend-text">{text}</span>
        </div>
    );
};
