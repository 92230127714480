import { AssessmentType, CompetencyProfile } from "../types";
import { ColorProperty } from "csstype";
import { IRange } from "./Range";
import { StringFormatter } from "../libs";
import { computed, observable } from "mobx";
import { computedFn } from "mobx-utils";

export type DistributionViewMode = "numbers" | "average";

export enum FitLevelEnum {
    BeVigilent,
    GoodFit,
    VeryGood,
    ShowAll,
}

export const stanineFitLevelMapping: Map<IRange, FitLevelEnum> = new Map<IRange, FitLevelEnum>([
    [{ min: 0, max: 2.99 }, FitLevelEnum.BeVigilent],
    [{ min: 3, max: 5.99 }, FitLevelEnum.GoodFit],
    [{ min: 6, max: 9 }, FitLevelEnum.VeryGood],
]);

// This mapping represent a fit level based on the same color mapping
// found in ScoreContainerComp.unipolarScoreToColorMapping and is used
// in competencies where we don't calculate a real FitLevel
export const stanineCompetencyFitLevelMapping: Map<IRange, FitLevelEnum> = new Map<IRange, FitLevelEnum>([
    [{ min: 1, max: 3 }, FitLevelEnum.BeVigilent],
    [{ min: 4, max: 6 }, FitLevelEnum.GoodFit],
    [{ min: 7, max: 9 }, FitLevelEnum.VeryGood],
]);

export type WeightLabel = "+" | "++" | "+++";

export interface IPersonScores {
    scores: Dictionary<string, number>;
    candidateProId: string;
    candidateDisplayName: string;
    candidatePictureUri?: string;
    color: ColorProperty;
    textColor: ColorProperty;
    isSelectedCandidate?: boolean;
}

export interface IPersonScore {
    score: number;
    candidateProId: string;
    candidateDisplayName: string;
    candidatePictureUri?: string;
    color: ColorProperty;
    textColor: ColorProperty;
}

export enum AssessmentDocumentType {
    GlobalScore = 0,
    Cognitive = 2,
    Personality = 3,
    Preferences = 4,
    Competences = 5,
    TripleBilan = 20,
    LearningMode = 22,
    PremiumCognitive = 103,
    PremiumPersonality = 104,
    PremiumPreferences = 105,
    PremiumTripleBottomLine = 107,
    PremiumLearningMode = 106,
}

export enum ScaleType {
    Unipolar,
    Bipolar,
}

export enum ScaleMaxScore {
    Likert = 5,
    Stanine = 9,
}

interface IAtcompatibilityFitCandidateDataStructure {
    candidateProId: string;
    assessmentId: string;
    score: number;
    strengths: string[];
    advices: string[];
}

export interface IAtCompatibilityFitStructure {
    advices: string[];
    strengths: string[];
    summary: string;
    candidatesData: IAtcompatibilityFitCandidateDataStructure[]; // MARK: Should always have 2 entries in the array
}

export interface IDimensionStructure {
    enumName: string;
    lowLabel: string;
    highLabel?: string;
    description?: string;
    weightLabel?: WeightLabel;
    range?: IRange;
    isInverted?: boolean;
    fitScore?: FitLevelEnum;
    atCompatibilityFitData?: IAtCompatibilityFitStructure;
}

export interface IDimensionGroupStructure {
    enumName: string;
    name: string;
    description: string;
    hidden: boolean;
    scaleLabels?: string[];
    dimensions: IDimensionStructure[];
}

export enum DrillDownLevelEnum {
    Assessment,
    Group,
    Dimension,
}

export interface IAtmanAssessmentDocumentStructure {
    assessmentDocumentType: AssessmentDocumentType;
    assessmentTypeEnum?: AssessmentType;
    scaleType: ScaleType;
    languageCode: string;
    title: string;
    description: string;
    maxScore: number;
    scaleLabels: string[];
    groups: IDimensionGroupStructure[];
    profile?: CompetencyProfile;
}

export class AtmanAssessmentDocumentStructure implements IAtmanAssessmentDocumentStructure {
    @observable public readonly assessmentDocumentType: AssessmentDocumentType;
    @observable public readonly assessmentTypeEnum?: AssessmentType;
    @observable public readonly scaleType: ScaleType;
    @observable public readonly languageCode: string;
    @observable public readonly title: string;
    @observable public readonly description: string;
    @observable public readonly maxScore: number;
    @observable public readonly scaleLabels: string[];
    @observable public readonly profile?: CompetencyProfile;
    @observable public groups: DimensionGroupStructure[];
    @observable public drillDownLevel: DrillDownLevelEnum = DrillDownLevelEnum.Assessment;

    @computed public get language2CharIso() {
        return StringFormatter.get2CharIsoLanguage(this.languageCode);
    }

    constructor(json: IAtmanAssessmentDocumentStructure) {
        this.assessmentDocumentType = json.assessmentDocumentType;
        this.assessmentTypeEnum = json.assessmentTypeEnum;
        this.scaleType = json.scaleType;
        this.languageCode = json.languageCode;
        this.title = json.title;
        this.description = json.description;
        this.maxScore = json.maxScore;
        this.scaleLabels = json.scaleLabels;
        this.profile = json.profile;
        this.groups = json.groups.map((x) => new DimensionGroupStructure(x));
    }
}

export class DimensionGroupStructure implements IDimensionGroupStructure {
    @observable public readonly description: string;
    @observable public readonly enumName: string;
    @observable public readonly hidden: boolean;
    @observable public readonly name: string;
    @observable public readonly scaleLabels?: string[];
    @observable public dimensions: DimensionStructure[];

    constructor(json: IDimensionGroupStructure) {
        this.description = json.description;
        this.enumName = json.enumName;
        this.hidden = json.hidden;
        this.name = json.name;
        this.scaleLabels = json.scaleLabels;
        this.dimensions = json.dimensions.map((x) => new DimensionStructure(x));
    }
}

export class DimensionStructure implements IDimensionStructure {
    @observable public readonly enumName: string;
    @observable public readonly lowLabel: string;
    @observable public readonly highLabel?: string;
    @observable public readonly description?: string;
    @observable public readonly weightLabel?: WeightLabel;
    @observable public readonly range?: IRange;
    @observable public readonly isInverted?: boolean;

    @observable public fitScore?: FitLevelEnum;
    @observable public atCompatibilityFitData?: IAtCompatibilityFitStructure;

    constructor(json: IDimensionStructure) {
        this.enumName = json.enumName;
        this.lowLabel = json.lowLabel;
        this.highLabel = json.highLabel;
        this.description = json.description;
        this.weightLabel = json.weightLabel;
        this.range = json.range;
        this.isInverted = json.isInverted;
        this.fitScore = json.fitScore;
        this.atCompatibilityFitData = json.atCompatibilityFitData;
    }

    public getFitScore = computedFn((score: number): FitLevelEnum | undefined => {
        const isRangeValid = (this.range?.min ?? 0) >= 1 && (this.range?.max ?? 0) <= 9;

        if (!this.range || !score || !isRangeValid) {
            return undefined;
        }

        const inRangeValue = this.isInverted ? FitLevelEnum.BeVigilent : FitLevelEnum.VeryGood;
        const closeToRangeValue = FitLevelEnum.GoodFit;
        const farFromRangeValue = this.isInverted ? FitLevelEnum.VeryGood : FitLevelEnum.BeVigilent;

        if (score >= this.range.min && score <= this.range.max) {
            return inRangeValue;
        }

        const differenceWithRangeMax = score - this.range.max;

        if (score > this.range.max && differenceWithRangeMax <= 2) {
            return closeToRangeValue;
        }

        const differenceWithRangeMin = this.range.min - score;

        if (score < this.range.min && differenceWithRangeMin <= 2) {
            return closeToRangeValue;
        }

        return farFromRangeValue;
    });
}

export enum PrintModeEnum {
    Summary,
    Detailed,
}

export enum TeamDevelopmentReportType {
    EmployeeAppraisal,
    ActionPlan,
    Full,
}

export enum DocumentSectionEnum {
    Summary = 1 << 0,
    Detailed = 1 << 1,
}
