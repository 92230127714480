import * as React from "react";
import { AppScope, ClientStore, PartnerStore, ProductCodeProEnum, UnhandledScopeError } from "@atman/business";
import { BaseColumn } from "../../../components/BaseItemList";
import { OwnersListDataEntry } from "./DashboardOwnersList";

export class OwnerNameColumn extends BaseColumn<OwnersListDataEntry, string> {
    constructor(scope: AppScope, clientStore: ClientStore, partnerStore: PartnerStore) {
        super();

        switch (scope) {
            case AppScope.Partner:
                this.text = "partnerApp.clientName".localize();
                break;
            case AppScope.Supplier:
                this.text = "supplierApp.partnerName".localize();
                break;
            default:
                throw new UnhandledScopeError(scope);
        }

        this.dataField = "ownerId";
        this.sort = false;
        this.formatter = (cell) => {
            let ownerName: string;

            switch (scope) {
                case AppScope.Partner:
                    const client = clientStore.getClientById(cell);

                    if (!client) {
                        return null;
                    }

                    ownerName = client.name;

                    break;
                case AppScope.Supplier:
                    const partner = partnerStore.getPartnerById(cell);

                    if (!partner) {
                        return null;
                    }

                    ownerName = partner.name;
                    break;
                default:
                    throw new UnhandledScopeError(scope);
            }

            return <span>{ownerName}</span>;
        };
    }
}

export class TotalUsageColumn extends BaseColumn<OwnersListDataEntry, number> {
    constructor() {
        super();

        this.text = "global.total".localize();
        this.dataField = "total";

        this.formatter = (cell) => <span className={"textWeightMedium"}>{cell}</span>;
    }
}

export class ProductUsageColumn extends BaseColumn<OwnersListDataEntry, number> {
    constructor(productCode: ProductCodeProEnum) {
        super();

        this.text = `global.productCodes.${ProductCodeProEnum[productCode].toCamel()}`.localize();
        // @ts-ignore
        this.dataField = productCode;

        this.formatter = (cell, row) => {
            let percentage = (cell / row.total) * 100;
            percentage = !isNaN(percentage) ? percentage : 0;

            return <span>{`${cell} (${percentage.toFixed(0)}%)`}</span>;
        };
    }
}
